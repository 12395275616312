/* You can add global styles to this file, and also import other style files */
.main_content {
    padding-top: 15px;
  }
  .breadcrumb ul {
    display: flex;
    align-items: center;
    column-gap: 30px;
  }
  .breadcrumb ul li {
    position: relative;
  }
  .breadcrumb ul li:not(:last-child):before {
    position: absolute;
    right: -25px;
    content: ">";
    height: 15px;
    width: 15px;
    top: 0px;
  }
  .breadcrumb ul li {
    color: black;
    user-select: contain;
    cursor: auto;
  }
  .breadcrumb ul li a:hover {
    text-decoration: underline;
  }
  .breadcrumb ul li a {
    color: #49aef4;
    font-size: 14px;
    font-weight: 400;
    display: block;
  }
  .main_content_feed {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    padding-top: 15px;
  }
  .aside_area {
    flex: 0 0 calc(25% - 10px);
  }
  .feed_area {
    flex: 0 0 calc(75% - 10px);
  }
  /* header bar */
  .aside_header_bar {
    display: flex;
    align-items: center;
    column-gap: 10px;
    background: transparent;
    border: 1px solid #f5911e;
    color: #f5911e;
    padding: 4px;
    width: 100%;
    cursor: pointer;
    font-size: 24px;
    user-select: none;
    letter-spacing: 15px;
  }
  /* For Aside Menu */
  .aside_area {
    position: relative;
  }
  .aside_header_menu {
    /* display: none; */
    position: absolute;
    background: #fff;
    height: auto;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    border: 2px solid #c6c6c681;
  }
  .as_menu_item span {
    background: #fff;
    width: 100%;
    padding: 15px 10px;
    cursor: pointer;
    position: relative;
    border-left: 5px solid transparent;
    display: flex;
    padding-right: 32px;
  }
  .as_menu_item span:hover {
    border-left-color: #49aef4;
  }
  .as_menu_item.active span {
    background: #49aef4;
    color: #fff;
    border: none;
    user-select: none;
  }
  .as_menu_item span:not(:last-child) {
    border-bottom: 1px solid #000;
  }
  .as_menu_item.active span:not(:last-child) {
    border-bottom: 1px solid #49aef4;
  }
  .as_menu_item span::after {
    position: absolute;
    content: ">";
    right: 10px;
    top: 13px;
    height: 20px;
    width: 20px;
    color: #000;
    font-weight: 700;
  }
  .as_menu_item.active span::after {
    color: #fff;
  }
  .as_menu_item span {
    font-weight: 600;
    font-size: 14px;
    user-select: none;
  }
  .as_sub_menu_title {
    font-size: 16px;
    font-weight: 400;
    border-bottom: 5px solid #f5911e;
    padding: 3px;
    padding-top: 5px;
    user-select: none;
  }
  .as_sub_menu {
    display: none;
    background: #fff;
  }
  .as_menu_item.active .as_sub_menu {
    display: block !important;
  }
  .as_sub_menu ul {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    row-gap: 10px;
    flex-wrap: wrap;
    padding-top: 4px;
    user-select: none;
  }
  .as_sub_menu ul li {
    flex: 0 0 calc(50% - 5px);
    border: 1px solid #000;
    border-top: none;
    border-left: none;
  }
  .as_sub_menu ul li a {
    padding: 4px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    width: 100%;
    user-select: none;
    color: #000;
  }
  .as_sub_menu ul li a:hover {
    text-decoration: underline;
  }
  /* For Aside Menu */
  .aside_content {
    border: 1px solid #d6d1b8;
    padding: 10px;
    background: transparent;
  }
  .as_ct_search {
    border-bottom: 1px solid #d6d1b8;
    position: relative;
  }
  .as_ct_search input {
    width: 100%;
    border: 2px solid rgba(198, 198, 198, 0.5058823529);
    outline: none;
    color: black;
    font-size: 16px;
    padding: 5px;
  }
  .as_ct_search input::placeholder {
    color: #c6c6c6;
  }
  .as_ct_search button {
    position: absolute;
    right: 10px;
    top: 7px;
    color: #c6c6c6;
  }
  .as_ct_search button:hover,
  .as_ct_search button:focus {
    color: black;
  }
  .as_ct_filter {
    padding-top: 10px;
  }
  .as_ct_single_item .as_subMenu_title {
    color: black;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    margin-top: 10px;
    user-select: none;
  }
  .as_ct_single_item .as_subMenu ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
    padding-top: 10px;
  }
  .as_ct_single_item .as_subMenu ul li {
    flex: 0 0 calc(50% - 5px);
  }
  .as_ct_single_item .as_subMenu ul li a {
    display: block;
    width: 100%;
    border: 1px solid #c6c6c6;
    padding: 4px;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    text-align: center;
  }
  .as_ct_single_item .as_subMenu ul li.selected a {
    background: #e0536e;
    color: #fff;
    border-color: #e0536e;
  }
  .as_ct_single_item .as_subMenu ul li:hover a {
    border-color: #000;
  }
  
  /* Feed content */
  .feed_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  .feed_header h4 {
    font-size: 14px;
    color: #555;
    margin: 0%;
    letter-spacing: 2px;
  }
  .feed_header span {
    font-size: 12px;
    color: #c6c6c6;
  }
  .single_feed_card {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
    padding: 20px;
    border-left: 10px solid transparent;
    padding-left: 10px;
    margin: 10px 0;
  }
  /* feed card devider */
  .feed_card_devider {
    border-bottom: 4px dotted #c6c6c6;
  }
  .single_feed_card:hover {
    border-left-color: #49aef4;
    background: #fdf8d6;
  }
  .feed_card_thumb {
    flex: 0 0 calc(20% - 10px);
  }
  .feed_card_thumb img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .feed_card_text {
    flex: 0 0 calc(60% - 10px);
  }
  .feed_card_action {
    flex: 0 0 calc(20% - 10px);
  }
  .feed_card_text h3 {
    font-weight: 500;
    color: black;
    font-size: 16px;
  }
  .feed_card_text .toursID {
    padding-top: 15px;
  }
  .feed_card_text .toursID span {
    color: #4d4d4d;
    font-size: 14px;
    font-weight: 400;
  }
  .feed_card_text .toursID a {
    color: #49aef4;
  }
  .feed_card_text .toursID a:hover {
    text-decoration: underline;
  }
  .feed_card_text .startDate {
    display: flex;
    align-items: center;
    column-gap: 5px;
    padding: 10px 0;
    padding-bottom: 25px;
  }
  .feed_card_text .startDate span {
    display: block;
    font-size: 14px;
    font-weight: 300;
  }
  .feed_card_text .list_order {
    border: 1px solid #3333338f;
    padding: 0px 10px 10px;
  }
  .feed_card_text .list_order h3 {
    margin-top: -20px;
    background: #fff;
    color: #4d4d4d;
    display: inline-block;
    font-size: 14px;
  }
  .single_feed_card:hover .feed_card_text .list_order h3 {
    background: #fdf8d6;
  }
  .feed_card_text .list_order ul.order_info {
    padding-top: 10px;
  }
  .feed_card_text .list_order ul.order_info,
  .feed_card_text .list_order ul.order_info > li {
    list-style-type: "-";
    padding-left: 10px;
  }
  .feed_card_text .list_order ul.order_info > li {
    font-size: 12px;
    font-weight: 400px;
    color: #000;
    padding-bottom: 5px;
  }
  .feed_card_text .list_order ul.order_info > li > a {
    color: #49aef4;
    font-weight: 500;
  }
  .feed_card_text .list_order ul.order_info > li > a:hover {
    text-decoration: underline;
  }
  .feed_card_text .order_done {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding-top: 5px;
  }
  .feed_card_text .order_done ul {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
  .feed_card_text .order_done p {
    font-size: 14px;
    color: #4d4d4d;
  }
  .feed_card_text .order_done ul li input {
    background: transparent;
    border: 1px solid #49aef4;
    display: block;
    padding: 4px 10px;
    font-size: 14px;
    font-weight: 500;
    color: #49aef4;
    cursor: pointer;
  }
  .feed_card_text .order_done ul li input:hover,
  .feed_card_text .order_done ul li input:focus {
    background: #49aef4;
    color: #fff;
  }
  .feed_card_text .daynum {
    font-size: 14px;
    color: #4d4d4d;
    padding-top: 5px;
  }
  /* feed_card_action */
  .feed_action_head {
    display: flex;
    column-gap: 10px;
    align-items: center;
    padding-bottom: 10px;
  }
  .feed_action_socail {
    padding-top: 10px;
  }
  .feed_action_socail ul {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  .feed_action_socail ul li {
    height: 55px;
    text-align: center;
  }
  .socailArea_tg {
    height: auto;
    background: #3eb649;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }
  .socailArea_tg a {
    display: block;
    height: 100%;
    width: 100%;
  }
  .socailArea_tg img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .feed_action_socail ul li a {
    display: block;
    padding: 5px;
  }
  .feed_action_socail ul li a img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .feed_action_socail ul li.priceArea_tg {
    background: red;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 10px;
  }
  .feed_action_socail ul li button {
    background: transparent;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    color: #fff;
    font-size: 20px;
    background: #49aef4;
    border-radius: 8px;
    padding: 10px;
  }
  .feed_action_socail ul li button:hover {
    text-decoration: underline;
  }
  .feed_populer_cards {
    border: 3px solid #f5911e;
    border-bottom-width: 10px;
  }
  .feed_populer_badge h3 {
    background: #f5911e;
    text-align: center;
    padding: 10px;
    color: #fff;
    font-size: 32px;
  }
  .aside_header_menu {
    display: none;
  }
  
  .aside_header_bar.active + .aside_header_menu {
    display: block;
  }

  .ng-calendar {
    display:flex;
  }
  .ng-calendar-day{
    width:75%;
    margin-left: 5px;
  }
  .ng-calendar-year{
    width:25%
  }
  
  /* Style for the active .aside_header_bar */
  /* Some Media quyari */
  @media screen and (max-width: 1200px) {
    .aside_header_bar {
      column-gap: 5px;
      padding: 5px;
      font-size: 20px;
      letter-spacing: 10px;
    }
  }
  @media screen and (max-width: 991px) {
    .main_content_feed {
      flex-direction: column;
      row-gap: 30px;
    }
    .feed_populer_badge h3 {
      font-size: 22px;
    }
  }
  @media screen and (max-width: 767px) {
    .single_feed_card {
      flex-direction: column;
      row-gap: 20px;
    }
    .feed_populer_badge h3 {
      font-size: 20px;
      padding: 8px;
    }
    .feed_action_socail ul {
      column-gap: 10px;
      flex-direction: row;
    }
    .feed_card_thumb {
      max-width: 350px;
      height: auto;
    }
  }
  @media screen and (max-width: 576px) {
    .feed_action_socail ul {
      column-gap: 10px;
      flex-direction: column;
    }
    .single_feed_card {
      padding: 10px;
      border-left: 5px solid transparent;
      padding-left: 5px;
    }
    footer {
      background: #2ecc702c;
      padding: 40px 0 20px;
    }
  }
  /* Some Media quyari */
/* Fonts */
@font-face {
    font-family: "Helvetica";
    src: url("/assets/fonts/HelveticaNeue.woff2") format("woff2"),
      url("/assets/fonts/HelveticaNeue.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Helvetica";
    src: url("/assets/fonts/HelveticaNeue-Bold.woff2") format("woff2"),
      url("/assets/fonts/HelveticaNeue-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "/assets/fonts/HelveticaNeue";
    src: url("/assets/fonts/HelveticaNeueRegular.woff2") format("woff2"),
      url("/assets/fonts/HelveticaNeueRegular.woff") format("woff");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Helvetica";
    src: url("/assets/fonts/HelveticaNeue-Medium.woff2") format("woff2"),
      url("/assets/fonts/HelveticaNeue-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Helvetica";
    src: url("/assets/fonts/HelveticaNeue-Italic.woff2") format("woff2"),
      url("/assets/fonts/HelveticaNeue-Italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Helvetica";
    src: url("/assets/fonts/HelveticaNeue-Thin.woff2") format("woff2"),
      url("/assets/fonts/HelveticaNeue-Thin.woff") format("woff");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Helvetica";
    src: url("/assets/fonts/HelveticaNeue-Light.woff2") format("woff2"),
      url("/assets/fonts/HelveticaNeue-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  /* Mian Css here */
  :root {
    --primary-color: #d10a11;
    --secondary-read: #cf0b10;
    --primary-soft-color: rgba(255, 0, 0, 0.315);
    --secondary-color: #ffe400;
    --text-color: #020202;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  *::after,
  *::before {
    box-sizing: inherit;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  a {
    display: inline-block;
    text-decoration: none;
  }
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  p {
    margin: 0;
    padding: 0;
  }
  button {
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
  }
  textarea,
  input {
    border: none;
    outline: none;
    font-family: inherit;
  }
  img {
    height: 100%;
    width: 100%;
  }
  input[type="search"]::-webkit-search-cancel-button {
    display: none; /* Hide the clear button */
  }
  .container {
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1440px;
    box-sizing: border-box;
  }
  /* Mian Css here */
  body {
    font-family: "Helvetica", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: var(--text-color);
  }
  /* header */
  /* For Header Top */
  .header_top {
    display: flex;
    background-image: url("/assets/img/wave-haikei.svg");
    background-position: top right;
    background-size: cover;
    justify-content: space-between;
    padding: 20px;
  }
  .header_top_logo {
    flex: 0 0 30%;
  }
  .header_top_content {
    flex: 0 0 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 20px 0;
  }
  .header_top_content ul {
    display: flex;
    column-gap: 40px;
    padding-bottom: 20px;
  }
  .header_top_content ul li {
    position: relative;
  }
  .header_top_content ul li:not(:last-child):before {
    position: absolute;
    height: 100%;
    width: 3px;
    background: #fff;
    content: "";
    top: 0;
    right: -20px;
  }
  .header_top_right_ul {
    display: flex;
    align-items: flex-start;
    column-gap: 30px;
  }
  .header_top_content ul li a {
    display: flex;
    align-items: center;
    column-gap: 10px;
    color: #fff;
    font-size: 28px;
    font-weight: 500;
    font-style: italic;
  }
  .header_top_content ul li a img {
    height: 25px;
    width: 25px;
    object-fit: contain;
  }
  .header_top_content li {
    color: #fff;
    font-size: 24px;
  }
  .header_top_right_ul h3 {
    font-size: 32px;
    font-style: italic;
    line-height: 1;
  }
  .header_top_right_ul h3 span {
    font-size: 24px;
  }
  /* For Table */
  .header_top_tables {
    display: flex;
    justify-content: space-between;
    column-gap: 30px;
    max-width: 550px;
    width: 100%;
  }
  .header_top_tables table tr td {
    color: #fff;
  }
  .header_top_tables table tr:nth-child(1) td {
    font-size: 18px;
  }
  .header_top_tables table tr td img {
    height: 22px;
    width: 22px;
  }
  .header_top_tables table tr td span:nth-child(2) {
    color: #fff;
    padding-left: 10px;
  }
  /* For Header Top */
  .header_menu {
    border-bottom: 10px solid var(--primary-color);
  }
  .header_menu nav ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header_menu nav ul li {
    width: 100%;
    height: 100%;
  }
  .header_menu nav ul li:not(:last-child) {
    border-right: 2px solid var(--text-color);
  }
  .header_menu nav ul li a {
    display: block;
    font-size: 19px;
    padding: 10px;
    background: var(--primary-soft-color);
    color: var(--text-color);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-weight: 500;
    text-align: center;
    height: 90px;
    padding: 15px 5px;
  }
  .header_menu nav ul li a:hover {
    text-decoration: underline;
  }
  .header_menu nav ul li a:hover {
    background: var(--primary-color);
    color: #fff;
  }
  /* mobile header */
  .mobile_header {
    display: none;
    background: #fff;
    padding: 5px;
    box-shadow: 2px 0px 5px #0000002c;
  }
  .mobile_header_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
  }
  .btn_home,
  .main_logo {
    display: inline-block;
  }
  .btn_home img {
    height: 50px;
    width: 100%;
    object-fit: contain;
  }
  .main_logo img {
    height: 75px;
    width: 100%;
    object-fit: contain;
  }
  /* Here area */
  .hero_menu ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 5px;
    padding: 20px 0;
  }
  
  .hero_menu ul li a {
    border: 2px solid #727171;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    transition: 0.3s all ease;
    font-size: 15px;
    color: #333;
    font-weight: bolder;
  }
  .hero_menu ul li a img {
    height: 20px;
    width: 20px;
    object-fit: contain;
  }
  .hero_menu ul li a:hover,
  .hero_menu ul li a:focus {
    background: var(--primary-color);
    border-color: var(--primary-color);
    color: #fff;
  }
  /* hero_content */
  .hero_content {
    display: flex;
    justify-content: space-between;
    column-gap: 15px;
  }
  .left_aside_menu {
    flex: 0 0 calc(22% - 15px);
  }
  .aside_menu .parent_menu {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
  }
  .aside_menu .parent_menu .menu_item > a {
    display: block;
    background-image: url("/assets/img//aside-menu/mt_homeMenu_tour_eur.png"  );
    
    width: 100%;
    max-width: 273px;
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 11px 10px;
    margin: 0;
    float: left;
    min-height: 20px;
    cursor: pointer;
    font-size: 15px;
    color: #333;
    font-weight: bolder;
  }
  /* .aside_menu ul li:nth-child(2) a{}
  .aside_menu ul li:nth-child(3) a{}
  .aside_menu ul li:nth-child(4) a{}
  .aside_menu ul li:nth-child(5) a{}
  .aside_menu ul li:nth-child(6) a{}
  .aside_menu ul li:nth-child(7) a{}
  .aside_menu ul li:nth-child(8) a{} */
  .aside_menu ul .menu_item {
    position: relative;
  }
  .subMenu {
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
    width: 600px;
    min-height: 365px;
    background: #7c1616;
    padding: 20px;
    z-index: 99;
  }
  .subMenu ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 20px;
    row-gap: 10px;
  }
  .subMenu ul li {
    flex: 0 0 calc(33.33% - 20px);
  }
  .subMenu ul li a {
    color: #fff;
  }
  /* For Hero Banner */
  .hero_banner {
    flex: 0 0 calc(60% - 15px);
    width: 100%;
  }
  .hero_banner img {
    width: 100%;
  }
  .right_aside_menu {
    flex: 0 0 calc(18%);
  }
  .right_aside_menu ul {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    column-gap: 5px;
  }
  .right_aside_menu ul li a {
    text-align: center;
    color: white;
    width: 100%;
    display: flex;
    padding: 14px 5px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    background: linear-gradient(180deg, #72d4ff 0%, #5bbaf0 100%);
  }
  .right_aside_menu ul li:nth-child(1) a {
    font-size: 14px;
  }
  .right_aside_menu ul li a img {
    max-width: 60px;
    height: 35px;
    object-fit: contain;
  }
  .right_aside_menu ul li:nth-child(1) a {
    background: linear-gradient(
      to bottom,
      #4bbf3d 0%,
      #4bbf3d 50%,
      #4bbf3d 51%,
      #4bbf3d 100%
    );
  }
  .right_aside_menu ul li:nth-child(2) a {
    background: linear-gradient(
      to bottom,
      #0aa0e8 0%,
      #2989d8 50%,
      #207cca 51%,
      #0186c7 100%
    );
  }
  .right_aside_menu ul li:nth-child(3) a {
    background: linear-gradient(
      to bottom,
      #5458a1 0%,
      #5458a1 50%,
      #5458a1 51%,
      #5458a1 100%
    );
  }
  .right_aside_menu ul li:nth-child(4) a {
    background: linear-gradient(
      0deg,
      rgba(0, 104, 55, 1) 0%,
      rgba(0, 104, 55, 1) 50%,
      rgba(40, 141, 77, 1) 51%,
      rgba(40, 141, 77, 1) 100%
    );
  }
  .right_aside_menu ul li:nth-child(5) a {
    background: linear-gradient(
      0deg,
      rgba(117, 76, 36, 1) 0%,
      rgba(117, 76, 36, 1) 50%,
      rgba(142, 95, 57, 1) 51%,
      rgba(142, 95, 57, 1) 100%
    );
  }
  .right_aside_menu ul li:nth-child(6) a {
    background: linear-gradient(
      to bottom,
      #efa93d 0%,
      #efa93d 50%,
      #ea8326 51%,
      #ea8326 51%,
      #ea8326 100%
    );
  }
  .right_aside_menu ul li:nth-child(7) a {
    background: linear-gradient(
      to bottom,
      #90c745 0%,
      #8eb92a 50%,
      #72aa00 51%,
      #5c9b44 100%
    );
  }
  .right_aside_menu ul li:nth-child(8) a {
    background: linear-gradient(
      to bottom,
      #06a3a9 0%,
      #06a3a9 52%,
      #018690 53%,
      #018690 100%
    );
  }
  .right_aside_menu ul li:nth-child(9) a {
    background: linear-gradient(
      to bottom,
      #b374ad 0%,
      #b374ad 50%,
      #834f9b 51%,
      #834f9b 100%
    );
  }
  .right_aside_menu ul li a:hover,
  .right_aside_menu ul li a:focus {
    opacity: 0.8;
  }
  .right_aside_menu ul li a:hover {
    text-decoration: underline;
  }
  .hero_single_slide img {
    height: 345px;
  }
  /* Here area */
  /* features */
  .features {
    padding: 40px 0;
  }
  .features_wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
  }
  .single_featrues {
    flex: 0 0 calc(50% - 10px);
  }
  /* features */
  /* blogs */
  .blogs {
  //  padding: 60px 0;
  }
  .blog_header {
    text-align: center;
    max-width: 450px;
    margin: 0 auto;
  }
  .blog_header h2 {
    font-size: 32px;
    text-transform: capitalize;
    font-weight: bold;
    padding-bottom: 15px;
  }
  .blog_header p {
    text-align: center;
    line-height: 1.4;
    font-weight: 300;
  }
  .blogs_content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 30px;
  //  padding-top: 60px;
  }
  .single_blog {
    flex: 0 0 calc(33.33% - 15px);
  }
  .blog_card_thumb {
    height: 250px;
    width: 100%;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
  }
  .blog_card_thumb img {
    border-radius: 10px 10px 0 0;
  }
  .blog_card_text {
    background: #88444415;
    padding: 20px;
    border-radius: 0 0 10px 10px;
  }
  .blog_card_text_top h3 {
    font-weight: 600;
    font-size: 18px;
    padding-bottom: 10px;
    color: var(--text-color);
    height:50px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .promo_code {
    font-size: 16px;
  }
  .blog_card_text_top p {
    padding-bottom: 10px;
    font-size: 16px;
    color: var(--text-color);
  }
  .blog_card_text_bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
  }
  .blog_card_text_bottom ul {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
  .join_btn img {
    height: 25px;
    width: 100%;
    object-fit: contain;
  }
  /* blogs */
  /* footer */
  footer {
    background: #ff000011;
    padding: 60px 0 20px;
  }
  .footer_top {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    row-gap: 15px;
    flex-wrap: wrap;
  }
  .footer_widget {
    flex: 0 0 calc(15% - 20px);
  }
  .socail_widget_title,
  .widget_title {
    padding-bottom: 15px;
    font-weight: bolder;
    color: var(--text-color);
    font-size: 24px;
  }
  .footer_widget ul {
    display: flex;
    flex-direction: column;
  }
  .footer_widget ul li a {
    color: var(--text-color) a6;
    font-size: 14px;
    padding: 5px 0;
    width: 100%;
  }
  .footer_widget ul li a:hover {
    text-decoration: underline;
    color: var(--text-color);
  }
  /* .widget socail */
  .widget_subtitle {
    font-size: 18px;
    font-weight: bolder;
    padding-bottom: 15px;
  }
  .socail_menu {
    display: flex;
    justify-content: space-between;
    flex-direction: row !important;
    flex-wrap: wrap;
  }
  .socail_menu li a {
    display: block;
  }
  .socail_menu li {
    flex: 0 0 100%;
  }
  .socail_menu li.social_mini_icon {
    flex: 0 0 calc(25% - 10px);
  }
  .footer_bottom {
    padding-top: 40px;
  }
  .footer_description {
    line-height: 1.5;
    font-weight: 500;
    font-size: 14px;
    padding-bottom: 30px;
  }
  .footer_widget_menu ul {
    display: flex;
    align-items: center;
    column-gap: 10px;
    flex-wrap: wrap;
    padding-bottom: 10px;
  }
  .footer_widget_menu ul li a {
    color: var(--text-color);
    font-size: 14px;
    font-weight: bolder;
  }
  .footer_widget_menu ul li a:hover {
    text-decoration: underline;
  }
  .footer_cp {
    font-size: 14px;
    color: var(--text-color);
  }
  /* footer */
  /* easychat */
  .easychat {
    height: 60px;
    width: 60px;
//    background: var(--primary-color);
    display: flex;
//    justify-content: center;
    align-items: center;
    column-gap: 5px;
    border-radius: 50%;
//    border-bottom-right-radius: 0px;
    position: fixed;
    bottom: 15px;
    right: 20px;
    z-index: 99;
    cursor: pointer;
    background-image: url("/assets/img/go2top.svg");
    background-size: 60px 60px;
  }
  .easychat span {
    display: block;
    height: 8px;
    width: 8px;
    background: #fff;
    border-radius: 50%;
  }
  /* easychat */
  /* Extra Css for Update */
  .main_content {
    padding-top: 15px;
  }
  .breadcrumb ul {
    display: flex;
    align-items: center;
    column-gap: 30px;
  }
  .breadcrumb ul li {
    position: relative;
  }
  .breadcrumb ul li:not(:last-child):before {
    position: absolute;
    right: -25px;
    content: ">";
    height: 15px;
    width: 15px;
    top: 0px;
  }
  .breadcrumb ul li {
    color: var(--text-color);
    user-select: contain;
    cursor: auto;
  }
  .breadcrumb ul li a:hover {
    text-decoration: underline;
  }
  .breadcrumb ul li a {
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 400;
    display: block;
  }
  .main_content_feed {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    padding-top: 15px;
  }
  .aside_area {
    flex: 0 0 calc(25% - 10px);
  }
  .feed_area {
    flex: 0 0 calc(75% - 10px);
  }
  /* header bar */
  .aside_header_bar {
    display: flex;
    align-items: center;
    column-gap: 10px;
    background: transparent;
    border: 1px solid #f5911e;
    color: #f5911e;
    padding: 4px;
    width: 100%;
    cursor: pointer;
    font-size: 24px;
    user-select: none;
    letter-spacing: 15px;
  }
  /* For Aside Menu */
  .aside_area {
    position: relative;
  }
  .aside_header_menu {
    /* display: none; */
    position: absolute;
    background: #fff;
    height: auto;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    border: 2px solid #c6c6c681;
  }
  .as_menu_item span {
    background: #fff;
    width: 100%;
    padding: 15px 10px;
    cursor: pointer;
    position: relative;
    border-left: 5px solid transparent;
    display: flex;
    padding-right: 32px;
  }
  .as_menu_item span:hover {
    border-left-color: var(--primary-color);
  }
  .as_menu_item.active span {
    background: var(--primary-color);
    color: #fff;
    border: none;
    user-select: none;
  }
  .as_menu_item span:not(:last-child) {
    border-bottom: 1px solid #000;
  }
  .as_menu_item.active span:not(:last-child) {
    border-bottom: 1px solid var(--primary-color);
  }
  .as_menu_item span::after {
    position: absolute;
    content: ">";
    right: 10px;
    top: 13px;
    height: 20px;
    width: 20px;
    color: #000;
    font-weight: 700;
  }
  .as_menu_item.active span::after {
    color: #fff;
    transform: rotate(90deg);
    right: 15px;
    top: 20px;
  }
  .as_menu_item span {
    font-weight: 600;
    font-size: 14px;
    user-select: none;
  }
  .as_sub_menu_title {
    font-size: 16px;
    font-weight: 400;
    border-bottom: 5px solid var(--secondary-color);
    padding: 3px;
    padding-top: 5px;
    user-select: none;
  }
  .as_sub_menu {
    display: none;
    background: #fff;
  }
  .as_menu_item.active .as_sub_menu {
    display: block !important;
  }
  .as_sub_menu ul {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    row-gap: 10px;
    flex-wrap: wrap;
    padding-top: 4px;
    user-select: none;
  }
  .as_sub_menu ul li {
    flex: 0 0 calc(50% - 5px);
    border: 1px solid #000;
    border-top: none;
    border-left: none;
  }
  
  .as_sub_menu ul.solo_items li {
    flex: 0 0 100%;
  }
  .as_sub_menu ul li a {
    padding: 4px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    width: 100%;
    user-select: none;
    color: #000;
  }
  .as_sub_menu ul li a:hover {
    text-decoration: underline;
  }
  /* For Aside Menu */
  .aside_content {
    border: 1px solid #d6d1b8;
    padding: 10px;
    background: transparent;
  }
  .as_ct_search {
    border-bottom: 1px solid #d6d1b8;
    position: relative;
  }
  .as_ct_search input {
    width: 100%;
    border: 2px solid rgba(198, 198, 198, 0.5058823529);
    outline: none;
    color: var(--text-color);
    font-size: 16px;
    padding: 5px;
  }
  .as_ct_search input::placeholder {
    color: #c6c6c6;
  }
  .as_ct_search button {
    position: absolute;
    right: 10px;
    top: 7px;
    color: #c6c6c6;
  }
  .as_ct_search button:hover,
  .as_ct_search button:focus {
    color: var(--text-color);
  }
  .as_ct_filter {
    padding-top: 10px;
  }
  .as_ct_single_item .as_subMenu_title {
    color: var(--text-color);
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    margin-top: 10px;
    user-select: none;
  }
  .as_ct_single_item .as_subMenu ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
    padding-top: 10px;
  }
  .as_ct_single_item .as_subMenu ul li {
    flex: 0 0 calc(50% - 5px);
  }
  .as_ct_single_item .as_subMenu ul li a {
    display: block;
    width: 100%;
    border: 1px solid #c6c6c6;
    padding: 4px;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    text-align: center;
  }
  .as_ct_single_item .as_subMenu ul li.selected a {
    background: #e0536e;
    color: #fff;
    border-color: #e0536e;
  }
  .as_ct_single_item .as_subMenu ul li:hover a {
    border-color: #000;
  }
  
  /* Feed content */
  .feed_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  .feed_header h4 {
    font-size: 14px;
    color: #555;
    margin: 0%;
    letter-spacing: 2px;
  }
  .feed_header span {
    font-size: 12px;
    color: #c6c6c6;
  }
  .single_feed_card {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
    padding: 20px;
    border-left: 10px solid transparent;
    padding-left: 10px;
    margin: 10px 0;
  }
  .single_feed_card_content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
  }
  /* feed card devider */
  .feed_card_devider {
    border-bottom: 4px dotted #c6c6c6;
  }
  .single_feed_card:hover {
    border-left-color: var(--primary-color);
    background: #fdf8d6;
  }
  .feed_card_thumb {
    flex: 0 0 calc(20% - 10px);
  }
  .feed_card_thumb img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .feed_card_text {
    flex: 0 0 calc(60% - 10px);
  }
  .feed_card_action {
    flex: 0 0 calc(20% - 10px);
  }
  .feed_card_text h3 {
    font-weight: 500;
    color: var(--text-color);
    font-size: 16px;
  }
  .feed_card_text .toursID {
    padding-top: 15px;
  }
  .feed_card_text .toursID span {
    color: #4d4d4d;
    font-size: 14px;
    font-weight: 400;
  }
  .feed_card_text .toursID a {
    color: var(--primary-color);
  }
  .feed_card_text .toursID a:hover {
    text-decoration: underline;
  }
  .feed_card_text .startDate {
    display: flex;
    align-items: center;
    column-gap: 5px;
    padding: 10px 0;
    padding-bottom: 25px;
  }
  .feed_card_text .startDate span {
    display: block;
    font-size: 14px;
    font-weight: 300;
  }
  .feed_card_text .list_order {
    border: 1px solid #3333338f;
    padding: 0px 10px 10px;
  }
  .feed_card_text .list_order h3 {
    margin-top: -20px;
    background: #fff;
    color: #4d4d4d;
    display: inline-block;
    font-size: 14px;
  }
  .single_feed_card:hover .feed_card_text .list_order h3 {
    background: #fdf8d6;
  }
  .feed_card_text .list_order ul.order_info {
    padding-top: 10px;
  }
  .feed_card_text .list_order ul.order_info,
  .feed_card_text .list_order ul.order_info > li {
    list-style-type: "-";
    padding-left: 10px;
  }
  .feed_card_text .list_order ul.order_info > li {
    font-size: 12px;
    font-weight: 400px;
    color: #000;
    padding-bottom: 5px;
  }
  .feed_card_text .list_order ul.order_info > li > a {
    color: var(--primary-color);
    font-weight: 500;
  }
  .feed_card_text .list_order ul.order_info > li > a:hover {
    text-decoration: underline;
  }
  .feed_card_text .order_done {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding-top: 5px;
  }
  .feed_card_text .order_done ul {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
  .feed_card_text .order_done p {
    font-size: 14px;
    color: #4d4d4d;
  }
  .feed_card_text .order_done ul li input {
    background: transparent;
    border: 1px solid var(--primary-color);
    display: block;
    padding: 4px 10px;
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-color);
    cursor: pointer;
  }
  .feed_card_text .order_done ul li input:hover,
  .feed_card_text .order_done ul li input:focus {
    background: var(--primary-color);
    color: #fff;
  }
  .feed_card_text .daynum {
    font-size: 14px;
    color: #4d4d4d;
    padding-top: 5px;
  }
  /* feed_card_action */
  .feed_action_head {
    display: flex;
    column-gap: 10px;
    align-items: center;
    padding-bottom: 10px;
  }
  .feed_action_socail {
    padding-top: 10px;
  }
  .feed_action_socail ul {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  .feed_action_socail ul li {
    height: 55px;
    text-align: center;
  }
  .socailArea_tg {
    height: auto;
    background: #3eb649;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }
  .socailArea_tg a {
    display: block;
    height: 100%;
    width: 100%;
  }
  .socailArea_tg img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .feed_action_socail ul li a {
    display: block;
    padding: 5px;
  }
  .feed_action_socail ul li a img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .feed_action_socail ul li.priceArea_tg {
    background: red;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 10px;
    font-size: larger;
  }
  .feed_action_socail ul li button {
    background: transparent;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    color: #fff;
    font-size: 20px;
    background: #2FCFCD;
    border-radius: 8px;
    padding: 10px;
  }
  .feed_action_socail ul li button:hover {
    text-decoration: underline;
  }
  .feed_populer_cards {
    border: 3px solid #f5911e;
    border-bottom-width: 10px;
  }
  .feed_populer_badge h3 {
    background: #f5911e;
    text-align: center;
    padding: 10px;
    color: #fff;
    font-size: 32px;
  }
  .aside_header_menu {
    display: none;
  }
  
  .aside_header_bar.active + .aside_header_menu {
    display: block;
  }
  /* Extra Css for Update */
  /* Pagination */
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  
  .prev,
  .next {
    font-size: 16px;
    text-decoration: none;
    color: #333;
    margin: 0 15px;
    font-weight: 500;
  }
  
  .prev:hover,
  .next:hover {
    text-decoration: underline;
  }
  
  .page-list {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
  }
  
  .page-list li {
    margin-right: 5px;
  }
  
  .page-list a {
    text-decoration: none;
    background-color: #f0f0f0;
    padding: 5px 10px;
    border-radius: 4px;
    color: #333;
  }
  .page-list a.active {
    background: var(--primary-color);
    color: #fff;
  }
  .page-list a:hover {
    background-color: #333;
    color: #fff;
  }
  /* Ltinerary */
  .itinerary_header {
    max-width: 1120px;
    margin: 0 auto;
  }
  .itinerary_logo {
    height: 160px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .itinerary_logo img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .memberloginSetting {
    margin-top: -40px;
    border-bottom: 5px solid var(--primary-color);
    position: relative;
    z-index: 1;
  }
  .memberloginSetting ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 3px;
  }
  .memberloginSetting ul li a {
    color: #fff;
    font-size: 16px;
    padding: 5px;
    background: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    min-width: 35px;
  }
  .memberloginSetting ul li a iconify-icon {
    font-size: 22px;
  }
  .memberloginSetting ul li:nth-child(2) a {
    background: #5f83c2;
  }
  .memberloginSetting ul li:nth-child(3) a {
    background: #5bb138;
  }
  .memberloginSetting ul li:nth-child(4) a {
    background: #7ecef4;
  }
  .memberloginSetting ul li:nth-child(1) a:hover {
    text-decoration: underline;
  }
  .memberloginSetting ul li a:hover {
    opacity: 0.8;
  }
  .itinerary {
    max-width: 960px;
    margin: 0 auto;
  }
  .itinerary_step a {
    display: block;
    user-select: none;
  }
  .itinerary_step img {
    cursor: pointer;
    user-select: none;
  }
  /* itinerary cards */
  .itinerary_cards {
    padding-bottom: 30px;
  }
  /* Single Card */
  .itinerary_card {
    border: 2px solid var(--primary-color);
    margin-bottom: 20px;
  }
  .itinerary_card_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: red;
    border-top: 5px;
    padding: 0px 15px;
    border-bottom: 0px solid var(--primary-color);
    color: #fff;
  }
  .itinerary_card_head h3 {
    font-weight: 600;
    color: #fff;
    font-size: 18px;
  }
  .itinerary_card_body {
    padding: 15px;
  }
  .ng-calendar {
    display:flex;
  }
  .updatePanel {
    display: flex;
    justify-content: space-between;
    column-gap: 25px;
    flex-wrap: wrap;
    row-gap: 25px;
  }
  .updatePanel .updatePanel_videoGrp {
    flex: 0 0 calc(45% - 15px);
  }
  .updatePanel .updatePanel_text {
    flex: 0 0 calc(55% - 15px);
  }
  /* Styles for the video container */
  .video-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    overflow: hidden;
  }
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  .updatePanel_videoGrp p {
    font-size: 14px;
    color: #6e6e6e;
    text-align: justify;
    line-height: 18px;
    padding-top: 15px;
  }
  .tagAndPriceGrp {
    padding-top: 30px;
  }
  .tagAndPriceGrp a {
    display: flex;
    align-items: center;
    column-gap: 5px;
    max-width: 160px;
    color: #0186c7;
  }
  .tagAndPriceGrp a:hover {
    text-decoration: underline;
  }
  .tagAndPriceGrp iconify-icon {
    background: #0186c7;
    color: #fff;
    border-radius: 4px 4px 0 0;
  }
  .updatePanel_text {
    font-size: 20px;
    font-weight: 400;
  }
  .updatePanel_text_desc {
    padding-top: 30px;
  }
  .updatePanel_text_desc ul li {
    font-size: 14px;
    display: block;
    line-height: 1.6;
  }
  .updatePanel_text_desc ul li:nth-child(2) {
    color: #6e6e6e;
  }
  
  .anchorSelect {
    color: #0186c7;
  }
  .anchorSelect:hover {
    text-decoration: underline;
  }
  .updatePanel_button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .updatePanel_button button {
    padding: 10px 40px;
    background: var(--primary-color);
    color: #fff;
  }
  .updatePanel_button button:hover {
    text-decoration: underline;
    opacity: 0.8;
  }
  .itinerary_card_table tr td {
    color: #000;
  }
  .itinerary_permision {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
  }
  .UpdatePanelTAC ul li {
    list-style-type: decimal;
    padding-left: 5px;
    margin-left: 20px;
  }
  .itinerary_permision .btn {
    padding: 10px 30px;
    font-weight: 600;
    font-size: 24px;
   // box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    cursor: pointer;
    font-family: inherit;
    border-radius: 4px;
  }
  .itinerary_permision .btn_danger {
    background: green;
  }
  .itinerary_permision .btn:hover {
 //    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.7);
  }
  .itinerary_permision .btn_primary {
    background: red;
  }
  .recommendGrp {
    border-left: 10px solid var(--primary-color);
    padding: 5px 0;
    margin-bottom: 20px;
  }
  .recommendGrp p {
    font-weight: 700;
    font-family: inherit;
    color: var(--text-color);
    font-size: 28px;
    padding-left: 10px;
  }
  /* Custome Checkbox */
  .UpdatePanelTAC_check {
    padding: 20px 0px;
  }
  .checkbox-container {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    user-select: none;
    font-size: 14px;
    color: var(--text-color);
  }
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border: 1px solid #ccc;
  }
  .checkbox-container input:checked ~ .checkmark {
    background-color: var(--primary-color);
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 6px;
    top: 2px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }
  /* updatePanelPrice table */
  .price_input-group {
    display: flex;
    gap: 0;
    align-items: center;
    background-color: #fff;
    transition: box-shadow 0.3s;
  }
  .price_input-number {
    width: 120px;
    outline: none;
    text-align: center;
    padding: 5px 10px;
    font-size: 16px;
    color: black;
    border: 1px solid #ccc;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s;
  }
  
  .btn {
    background-color: transparent;
    outline: none;
    cursor: pointer;
    font-size: 20px;
    padding: 5px 10px;
    transition: background-color 0.3s, color 0.3s;
  }
  .price_input-group .btn {
    background: #fff;
    border: 1px solid #ccc;
    color: var(--text-color);
    font-size: 16px;
    padding: 5px 10px;
    font-weight: 700;
  }
  .price_input-group .btn.minus {
    border-right: none;
  }
  .price_input-group .btn.plus {
    border-left: none;
  }
  .price_input-group .btn:hover {
    background-color: red;
    color: #fff;
  }
  /* updatePanel insurance */
  .updatePanelPrice_table {
    width: 100%;
  }
  .updatePanelPrice_table thead tr td {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 20px;
  }
  .updatePanelPrice_table tbody tr td {
    padding-bottom: 10px;
    font-size: 14px;
  }
  .updatePanelPrice_table tbody tr td:nth-child(1) span {
    display: block;
    color: var(--primary-color);
  }
  .updatePanelInsurance h3 {
    font-size: 18px;
    font-weight: 400;
    color: var(--text-color);
    padding-bottom: 10px;
    padding-top: 40px;
  }
  .updatePanelInsurance_table {
    width: 100%;
  }
  .updatePanelInsurance_table thead tr td {
    font-size: 14px;
    color: var(--text-color);
    padding-bottom: 10px;
  }
  .updatePanelInsurance_table tbody tr td {
    font-size: 14px;
  }
  .updatePanel_insurance_action_check {
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 250px;
    padding-bottom: 20px;
  }
  .import_msg {
    color: var(--primary-color);
    font-size: 13px;
    line-height: 1.6;
  }
  .UpdatePanelTAC_check {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 15px;
  }
  .insurance_taxFee ul {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
  }
  /* Example Breadcrumb */
  /* Booking page payment methord */
  .itinerary_paymentMethord {
    display: flex;
    align-items: center;
    column-gap: 20px;
    justify-content: space-between;
  }
  .single_payment {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
  .single_payment img {
    height: 40px;
  }
  .itinerary_paymentMethord .checkmark {
    transform: translateY(10px);
  }
  .itinerary_paymentOptions_content {
    display: flex;
  //  max-width: 450px;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 20px;
  }
  .itinerary_paymentOptions p {
    padding-top: 15px;
    font-size: 16px;
    font-weight: 400;
    color: var(--text-color);
  }
  .itinerary_paymentOptions .ifSp_5Active {
    display: none;
    color: var(--primary-color);
    font-size: 14px;
  }
  .terms_condition_check {
    padding: 20px;
    padding-top: 20px;
  }
  .booking_table_body p {
    font-size: 16px;
    color: var(--text-color);
    padding-bottom: 15px;
  }
  .booking_table table {
    width: 100%;
  }
  .booking_table table tr td {
    font-size: 16px;
    color: var(--text-color);
    padding: 10px 5px;
  }
  .booking_table table tr td a {
    color: #337ab7;
  }
  .booking_table table tr td a:hover {
    text-decoration: underline;
  }
  .table_header td {
    border-top: 2px solid var(--primary-color);
    padding-top: 5px;
    margin-top: 10px;
  }
  .table_header.totalAmount td {
    background: #00913a;
    text-align: center;
    color: #fff;
  }
  .registration_details_text {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
  }
 // .registration_details_text p {
 //   flex: 0 0 calc(70 - 15px);
 // }
  .registration_detailsCheck {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    flex: 0 0 calc(30% - 15px);
  }
  .registration_detailsSelect {
    padding-top: 20px;
  }
  .registration_detailsSelect label {
    font-size: 16px;
    color: var(--text-color);
    padding-bottom: 10px;
    display: block;
  }
  .registration_detailsSelect select {
    max-width: 450px;
    padding: 10px;
    border-radius: 4px;
    outline: none;
    border: 1px solid var(--text-color);
    color: var(--text-color);
  }
  .registration_details_input {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 20px;
    padding-top: 20px;
  }
  .registration_details_input p {
    flex: 0 0 calc(70% - 15px);
  }
  .registration_input {
    flex: 0 0 calc(30% - 15px);
    width: 100%;
  }
  .registration_details_input input {
    width: 100%;
    border: 1px solid var(--text-color);
    border-radius: 4px;
    padding: 10px;
    font-family: inherit;
    color: var(--text-color);
    font-size: 16px;
  }
  

  @media screen and (max-width: 1200px) {
    .header_menu nav ul li a {
      font-size: 16px;
    }
    .hero_content {
      row-gap: 25px;
      flex-wrap: wrap;
    }
    .left_aside_menu {
      flex: 0 0 calc(30% - 10px);
    }
    .hero_banner {
      flex: 0 0 calc(70% - 10px);
      width: 100%;
    }
    .right_aside_menu {
      flex: 0 0 100%;
      order: -1;
    }
    .right_aside_menu ul {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .right_aside_menu ul li {
      width: 100%;
      flex: 1 1 calc(33.33% - 5px);
    }
    .right_aside_menu ul li a {
      height: 60px;
      width: 100%;
    }
    .footer_widget {
      flex: 0 0 calc(33% - 20px);
    }
    .widget_title {
      font-size: 22px;
    }
    /* Update for Extra */
    .aside_header_bar {
      column-gap: 5px;
      padding: 5px;
      font-size: 20px;
      letter-spacing: 10px;
    }
    .ng-calendar {
      display:flex;
    }
    .ng-calendar-day{
      width:75%;
      margin-left: 5px;
    }
    .ng-calendar-year{
      width:25%
    }
  }
  @media screen and (max-width: 991px) {
    .header_top,
    .header_menu {
      display: none;
    }
    .mobile_header {
      display: block;
    }
    .single_blog {
      flex: 0 0 calc(50% - 15px);
    }
    .socail_widget_title,
    .widget_title {
      font-size: 20px;
    }
    /* Update for Extra */
    .main_content_feed {
      flex-direction: column;
      row-gap: 30px;
    }
    .feed_populer_badge h3 {
      font-size: 22px;
    }
    .feed_card_thumb {
      flex: 0 0 100%;
    }
    .feed_card_thumb img {
      height: 250px;
    }
    .feed_card_text {
      flex: 0 0 calc(70% - 10px);
    }
    .feed_card_action {
      flex: 0 0 calc(30% - 10px);
    }
    .ng-calendar {
      display:flex;
    }
    .ng-calendar-day{
      width:75%;
      margin-left: 5px;
    }
    .ng-calendar-year{
      width:25%;
    }
  }
  @media screen and (max-width: 767px) {
    .single_blog,
    .single_featrues {
      flex: 0 0 100%;
      width: 100%;
    }
    .hero_banner {
      flex: 0 0 100%;
      width: 100%;
    }
    .right_aside_menu {
      order: 0;
    }
    .hero_content {
      flex-direction: column-reverse;
    }
    .right_aside_menu ul {
      flex-wrap: wrap;
    }
    .right_aside_menu ul li {
      width: 100%;
      flex: 0 0 calc(50% - 2.5px);
    }
    .socail_widget_title,
    .widget_title {
      padding-bottom: 10px;
      font-size: 18px;
    }
    /* Update for Extra */
    .single_feed_card {
      flex-direction: column;
      row-gap: 20px;
    }
    .feed_populer_badge h3 {
      font-size: 20px;
      padding: 8px;
    }
    .feed_action_socail ul {
      column-gap: 10px;
      flex-direction: row;
    }
    .feed_card_thumb {
      max-width: 350px;
      height: auto;
    }
    /* Blog Card */
    .blog_card_text {
      padding: 15px;
    }
    .blog_card_text_top h3 {
      font-size: 16px;
      padding-bottom: 8px;
    }
    .blog_card_text_top p {
      font-size: 14px;
    }
    .hero_menu {
      display: none;
    }
    .hero {
      padding-top: 10px;
    }
    .feed_card_text {
      flex: 0 0 100%;
    }
    .feed_card_action {
      flex: 0 0 100%;
    }
    .itinerary_logo img {
      height: 120px;
    }
    .itinerary_logo {
      align-items: flex-start;
    }
    .itinerary_card_head h3 {
      font-size: 16px;
    }
    .itinerary_permision .btn {
      font-size: 22px;
    }
    /* For Itinerary Page */
    .updatePanel .updatePanel_text,
    .updatePanel .updatePanel_videoGrp {
      flex: 0 0 100%;
    }
    .updatePanel_text p {
      font-size: 14px;
    }
    .recommendGrp p {
      font-size: 24px;
    }
    .ng-calendar {
      display:block;
    }
    .ng-calendar-day{
      width:100%;
      height:400px;
      margin-left:0px;
    }
    .ng-calendar-year{
      width:100%;
      height:400px;
    }
  }
  @media screen and (max-width: 576px) {
    
    .itinerary_step ul {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: center;
      position: relative;
      max-width: 80px;
      margin: 0 auto;
    }

    .footer_widget ul li a {
      display: block;
      height: 100%;
      width: 100%;
      max-width: 200px;
    }
    .right_aside_menu ul li {
      width: 100%;
      flex: 0 0 100%;
    }
    .footer_widget {
      flex: 0 0 100%;
    }
    .container {
      padding: 0 10px;
    }
    .socail_widget_title,
    .widget_title {
      padding-bottom: 10px;
      font-size: 16px;
    }
    .social_mini_icon a {
      display: block;
      height: 100%;
      width: 100%;
    }
    .socail_menu {
      justify-content: flex-start;
    }
    .social_mini_icon a img {
      max-width: 60px;
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
    .footer_widget ul li a {
      font-size: 12px;
    }
    .footer_cp {
      font-size: 12px;
    }
    .footer_widget_menu ul li a {
      font-size: 12px;
    }
    .footer_description {
      font-size: 12px;
    }
    .widget_subtitle {
      font-size: 16px;
      padding-bottom: 10px;
    }
    /* Update for Extra */
    .feed_action_socail ul {
      column-gap: 10px;
      flex-direction: column;
    }
    .single_feed_card {
      padding: 10px;
      border-left: 5px solid transparent;
      padding-left: 5px;
    }
    footer {
      background: #2ecc702c;
      padding: 40px 0 20px;
    }
    .socail_menu li:nth-child(1),
    .socail_menu li:nth-child(2) {
      flex: 0 0 calc(50% - 5px);
    }
    /* Blogs */
    .blogs {
      padding: 40px 0;
    }
    .blog_header h2 {
      font-size: 24px;
      padding-bottom: 10px;
    }
    .blogs_content {
      row-gap: 20px;
      padding-top: 30px;
    }
    /* Footer */
    .widget_title {
      border-bottom: 1px solid black;
      position: relative;
      cursor: pointer;
    }
    .footer_widget ul.widget-content {
      display: block;
    }
    .widget_title::before {
      position: absolute;
      right: 10px;
      top: 2px;
      content: url("/assets/img/chevron-down.svg");
    }
    /* Booking Page */
    .booking_table table tr td {
      font-size: 16px;
      color: var(--text-color);
      padding: 5px 2px;
      font-size: 14px;
    }
    .itinerary_paymentMethord {
      flex-direction: column;
      row-gap: 20px;
      justify-content: flex-start;
      align-items: flex-start;
      padding-bottom: 20px;
    }
    .registration_details_text {
      flex-wrap: wrap;
      row-gap: 20px;
    }
    .registration_detailsCheck {
      flex: 0 0 100%;
      max-width: 200px;
    }
    .registration_details_input {
      row-gap: 20px;
    }
    .registration_input {
      flex: 0 0 100%;
      width: 100%;
    }
    .itinerary_card_head {
      padding: 8px 15px;
      display:block;
    }
    .itinerary_permision .btn {
      font-size: 18px;
    }
    .itinerary_card {
      border: 5px solid var(--primary-color);
      margin-bottom: 20px;
    }
    .ng-calendar {
      display:block;
      padding:3px;
      margin-left:0px;
    }
    .ng-calendar-day{
      width:100%;
      height:50%;
      margin-left:0px;
    }
    .ng-calendar-year{
      width:100%;
      height: 50%;
    }
  }
  

  .fc .fc-toolbar-title {
    font-size: 1.1em;
    margin: 0px;
}

 /* For Steps */
.itinerary_step {
  padding: 20px 0;
}
.itinerary_step ul {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
  position: relative;
  max-width: 850px;
  margin: 0 auto;
}
/* Normal state */
.itinerary_step ul li {
  display: block;
  max-width: 170px;
  width: 100%;
  padding: 10px 20px;
  color: #5d6174;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 50px;
  position: relative;
}
.itinerary_step ul li iconify-icon {
  font-size: 50px;
  color: #5d6174;
}
.itinerary_step ul li::before {
  height: 4px;
  width: 100%;
  background: #5d6174;
  content: "";
  position: absolute;
  top: inherit;
  left: 0;
  right: 0;
  transform: translateY(15px);
}
.itinerary_step ul li:nth-child(1)::before {
  width: 50%;
  margin-left: auto;
}
.itinerary_step ul li:last-child:before {
  width: 50%;
  margin-right: auto;
}
.itinerary_step ul li::after {
  position: absolute;
  height: 30px;
  width: 30px;
  background: #5d6174;
  content: "";
  border-radius: 50%;
  left: 0;
  margin: 0 auto;
  right: 0;
  top: 49%;
}
.itinerary_step ul li.steps_active::before,
.itinerary_step ul li.steps_active::after {
  background: #1aca8e;
}
.itinerary_step ul li.steps_active iconify-icon,
.steps_active span {
  color: #1aca8e;
}


@media screen and (min-width: 1200px) {
  .al_mobile_menu,
  .al_mobile_bar {
    display: none;
  }
}

@media screen and (max-width: 1280px) {
  .header_top_search_buttons ul li a {
    font-size: 11px;
  }
}
@media screen and (max-width: 1200px) {
  .new_header_top {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .header_top_search {
    grid-row: 2;
    grid-column: 1 / 3;
  }
  .header_top_search_buttons {
    border-top: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .header_top_search .header_top_contact_branch h4 {
    justify-content: center;
  }
  .al_mb_hide {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .new_header_top {
    display: block;
  }
  .header_top_contact_branch ul,
  .header_top_contact_branch h4 {
    justify-content: center;
  }
  .header_top_contact {
    border-bottom: 1px solid #ccc;
  }
  .logo_area a img {
    max-height: 120px;
  }
  .logo_area a {
    max-height: 75px;
  }
  /* Extra for steps */
  .itinerary_step ul li {
    font-size: 15px !important;
  }
  .itinerary_step ul li iconify-icon {
    font-size: 35px !important;
  }
  .itinerary_step ul li::after {
    height: 20px !important;
    width: 20px !important;
    top: 51%;
  }
  .itinerary_step ul li::before {
    height: 2px !important;
    transform: translateY(12px) !important;
  }
  .itinerary_step ul li {
    padding: 10px 3px !important;
    row-gap: 50px !important;
  }
}
@media screen and (max-width: 767px) {
  .header_top_search_top {
    flex-direction: column;
    row-gap: 10px;
  }
  .header_top_search_buttons ul {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 576px) {
  .itinerary_step ul li {
    font-size: 11px !important;
  }
}
@media screen and (max-width: 400px) {
  .header_top_contact_buttons a small {
    font-size: 14px;
  }
  .btn_cta strong {
    font-size: 24px;
    display: block;
    font-weight: 700;
  }
} 
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.c-carousel .carousel-indicators {
  position: absolute !important;
  bottom: 10px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  display: flex !important;
  justify-content: center !important;
  gap: 8px !important;
  z-index: 10 !important;
}

.c-carousel .carousel-indicators [data-coreui-target] {
  width: 12px !important;
  height: 12px !important;
  background-color: #888 !important;
  border-radius: 50% !important;
  transition: background-color 0.3s ease-in-out !important;
}

.c-carousel .carousel-indicators .active {
  background-color: #007bff !important;
}